import React from "react";
import styled from "styled-components";
import { Helmet } from "react-helmet";
import Layout from "../layout";
import config from "../../data/SiteConfig";
import { ReactComponent as Logo } from "../images/MayaLogo.svg";
import { useIntl, FormattedMessage } from "gatsby-plugin-intl";
import { useQueryParam, StringParam } from "use-query-params";
import { useApiData } from "react-api-data";
import { Heart } from "react-awesome-spinners";

const FoundationThanks = (props) => {
  const intl = useIntl();
  const [id, setId] = useQueryParam("id", StringParam);
  const payment = useApiData("getPayment", { id });
  const { data } = payment;
  return (
    <Layout>
      <Helmet title={`Thank you | ${config.siteTitle}`} />
      <OrderContainer>
        <CardContainer>
          <LogoContainer>
            <Logo />
          </LogoContainer>
          {payment.request.networkStatus === "loading" ? (
            <HeartContainer>
              <Heart color="#F71834" />
            </HeartContainer>
          ) : (
            <>
              {payment.request.networkStatus === "failed" && (
                <Text>
                  <FormattedMessage id="SomethingWrong" />
                </Text>
              )}
              {payment.request.networkStatus === "success" &&
              payment.data.status === "paid" ? (
                <Text>
                  <FormattedMessage
                    id="thanksDonation"
                    defaultMessage="Bedankt voor je donatie"
                  />
                </Text>
              ) : (
                <>
                  <Text>
                    <FormattedMessage
                      id="orderCanceled"
                      defaultMessage="Je bestelling is geannuleerd"
                    />
                  </Text>
                </>
              )}
            </>
          )}
        </CardContainer>
      </OrderContainer>
    </Layout>
  );
};

const OrderContainer = styled.div`
  width: 100%;
  height: 100vh;
  background: url("http://res.cloudinary.com/djhc9jhiq/image/upload/v1530633075/_MG_1546_u5jxkv.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  display: flex;
  justify-content: center;
`;

const HeartContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CardContainer = styled.div`
  width: 360px;
  display: flex;
  height: 360px;
  flex-direction: column;
  background: white;
  margin-top: 30px;
  padding: 24px;
  border-radius: 3px;
`;

const LogoContainer = styled.div`
  margin: auto;
  margin-top: 0px;
  margin-bottom: 33px;
`;

const Text = styled.p`
  font-size: 22px;
  font-family: "Nunito Sans";
  color: #333;
  text-align: center;
`;

const Button = styled.div`
  width: 100%;
  height: 40px;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  background-color: #f58320;
  font-family: "Nunito Sans";
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 36px;
  cursor: pointer;
  color: white;
  letter-spacing: 0.2px;
  text-align: center;
`;

export default FoundationThanks;
